<template>
  <div class="container-fluid">
    <md-card>
      <template v-if="form">
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">{{ form.name }}</h4>
        </md-card-header>

        <md-card-content>
          <tgc-dynamic-forms
            :form="form"
            @submit="onSubmit"
            @input="onChange"
          />
        </md-card-content>
      </template>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "test2",
  data: () => ({
    form: null,
    stepper: {},
  }),
  computed: {
    ...mapGetters(["getTheme"]),
  },
  methods: {
    getDynamicFormStepper() {
      this.$store
        .dispatch("dynamicForm/getDynamicForm", 2)
        .then((form) => {
          this.form = form;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onSubmit({ inputs, expectedValues, form, step }) {
      //Here we can use form submit event with form data and expected values summary (form by form)
      console.log("Submit form event!");
      console.log("Inputs: ", inputs);
      console.log("Expected values: ", expectedValues);
      console.log("Form: ", form);
      console.log("Step: ", step);
    },
    onChange(formPackage) {
        //Here we can watch the entire stepper form
        console.log(formPackage)
    },
  },
  mounted() {
    this.getDynamicFormStepper();
  },
};
</script>